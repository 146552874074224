import React from "react";
import { NavLink } from "react-router-dom";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import "./Error404.css";

const Error404 = () => {
  return (
    <>
      <Header />
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h6 className="notfound-heading">WE ARE SORRY, PAGE NOT FOUND</h6>
          <p>
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </p>
          <NavLink className="notfound-btn" to="/home">
            Back to Home Page
          </NavLink>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Error404;
