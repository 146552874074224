import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./Careers.css";
import { Button } from "@mui/material";
const careerBGImage = "/assets/roberto-sorin-ZZ3qxWFZNRg-unsplash 1 (1).webp";
const Careers = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Header />
      <div className="career-container">
        <img src={careerBGImage} className='career-bg-img'/>
        <div className="career-page-career-section">
          <div className="careers-title">
            <h1
              style={{
                color: "white",
                fontSize: "xx-large",
                textAlign: "center",
              }}
            >
              Careers
            </h1>
            <svg
              width="249"
              height="40"
              viewBox="0 0 249 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-2.18557e-07"
                y1="19.5"
                x2="53"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="63"
                y1="19.5"
                x2="249"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="55.5"
                y1="1.09278e-07"
                x2="55.5"
                y2="40"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="65.5"
                y1="9"
                x2="65.5"
                y2="31"
                stroke="#F0A202"
                stroke-width="5"
              />
            </svg>
          </div>
          <div className="career-title-section">
            <h1
              style={{
                fontSize: "56px",
                lineHeight: "67.2px",
                fontWeight: "600",
              }}
            >
              Shaping the battery world
            </h1>
          </div>
          <div className="career-description-contact">
            <p>
              Join our team to transform what’s next. Immerse yourself in an
              atmosphere of innovative solutions.
            </p>
          </div>
        </div>
        <div className="careers-middle-grid-container">
          <div className="careers-middle-grid-item">
            <div>
              <p className="careers-middle-grid-title">Feb 2022</p>
              <p className="careers-middle-grid-subTitle">
                Feb Expirenced Professionals
              </p>
              <p className="careers-middle-grid-para">
                MagPower lets you draw upon its superlative treasure of
                experience and expertise to add to yours
              </p>
            </div>
            <p className="careers-button">
              To Apply send your resume to career@magpower.in
            </p>
          </div>

          <div className="careers-middle-grid-item">
            <div>
              <p className="careers-middle-grid-title">March 2022</p>
              <p className="careers-middle-grid-subTitle">Entry Level Hiring</p>
              <p className="careers-middle-grid-para">
                Stay ahead in the race by giving your career a kickstart at
                MagPower
              </p>
            </div>
            <p className="careers-button">
              To Apply send your resume to career@magpower.in
            </p>
          </div>

          <div className="careers-middle-grid-item">
            <div>
              <p className="careers-middle-grid-title">May 2022</p>
              <p className="careers-middle-grid-subTitle">Internship Program</p>
              <p className="careers-middle-grid-para">
                Accelerate your career and learn with us at MagPower
              </p>
            </div>
            <p className="careers-button">
              To Apply send your resume to career@magpower.in
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Careers;
