import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../styles/header.css";

function Header(props) {
  const navigate = useNavigate();
  const pathname =
    window.location.href.split("/")[window.location.href.split("/").length - 1];

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light navbar-main">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Flogo%2FMagpower-logo.svg?alt=media&token=ae035512-edb2-45cd-ba5b-9249e643e2ef"
          alt="MagPower logo"
          className="navbar-logo-img"
          onClick={() => navigate("/")}
        />

        {/*  <NavLink className="navbar-brand" to="/home">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Flogo%2FMagpower-logo.svg?alt=media&token=ae035512-edb2-45cd-ba5b-9249e643e2ef"
            alt="MagPower logo"
            className="navbar-logo-img"
          />
        </NavLink> */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto navbar-link-wrapper navbar-items-container">
            <li className="nav-item nav-list-item">
              <NavLink
                className={
                  pathname === "home"
                    ? "navbar-links navbar-links-selected"
                    : "navbar-links"
                }
                to="/home"
              >
                Home{" "}
              </NavLink>
            </li>
            <li className="nav-item nav-list-item">
              <NavLink
                className={
                  pathname === "products"
                    ? "navbar-links navbar-links-selected"
                    : "navbar-links"
                }
                to="/products/ev"
              >
                Products
              </NavLink>
            </li>
            <li className="nav-item nav-list-item">
              <NavLink
                className={
                  pathname === "careers"
                    ? "navbar-links navbar-links-selected"
                    : "navbar-links"
                }
                to="/careers"
              >
                Careers
              </NavLink>
            </li>
            <li className="nav-item nav-list-item">
              <NavLink
                className={
                  pathname === "gallery"
                    ? "navbar-links navbar-links-selected"
                    : "navbar-links"
                }
                to="/gallery"
              >
                Gallery
              </NavLink>
            </li>
            <li className="nav-item nav-list-item">
              <NavLink
                className={
                  pathname === "aboutus"
                    ? "navbar-links navbar-links-selected"
                    : "navbar-links"
                }
                to="/aboutus"
              >
                About us
              </NavLink>
            </li>
            <li className="nav-item nav-list-item">
              <NavLink
                className={
                  pathname === "contact"
                    ? "navbar-links navbar-links-selected"
                    : "navbar-links"
                }
                to={"/contact"}
              >
                Contact us
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;
