import React, { useState } from "react";

function App(props) {
  const [state, setState] = useState([0, 0, 0, 0, 0, 0]);
  const [selected, setSelected] = useState(null);
  return (
    <div>
      <div>
        <div style={{ display: "flex", gap: "1%", flexWrap: "wrap" }}>
          {state.map((data, index) => {
            return (
              <div style={{ flexBasis: "33%" }}>
                <button
                  style={{
                    width: selected == index ? "150px" : "100px",
                    height: "40px",
                  }}
                  onClick={() => {
                    setSelected(index);
                  }}
                >
                  Index {index}{" "}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
