import React from "react";
import { Route, Routes } from "react-router-dom";
import Demo from "../components/Demo";
import Error404 from "../components/errorpage/Error404";
import Header from "../components/header/Header";
import About from "../pages/about/About";
import Careers from "../pages/careers/Careers";
import Contact from "../pages/contact/Contact";
import GalleryPage from "../pages/gallery/GalleryPage";
import ImageGallery from "../pages/gallery/ImageGallery";
import Home from "../pages/home/Home";
import Products from "../pages/products/Products";

function RouteConfig() {
  return (
    <div style={{ scrollBehavior: "smooth" }}>
      {/* <!-- <i class="fa fa-whatsapp whatsapp-icon"></i> --> */}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/products/:name" element={<Products />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/aboutus" element={<About />} />
        <Route exact path="/gallery" element={<GalleryPage />} />
        <Route path="*" element={<Error404 />} />
        <Route exact path="/demo" element={<Demo />} />
      </Routes>
    </div>
  );
}

export default RouteConfig;
