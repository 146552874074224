import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import swal from "sweetalert";

function ContactUs(props) {
  const [phoneNumberAlert, setPhoneNumberAlert] = useState(false);
  const [userState, setUserState] = useState({
    currentUser: {
      fullName: "",
      companyName: "",
      phoneNumber: "",
      email: "",
      city: "",
      message: "",
    },
  });
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   window.Email.send({
  //     Host: "smtp.yourisp.com",
  //     Username: "username",
  //     Password: "password",
  //     To: userState?.currentUser?.email ,
  //     From: "you@isp.com",
  //     Subject: userState?.currentUser?.message,
  //     Body: `Hi ${userState?.currentUser?.fullName}! We will call you regarding your query : ${userState?.currentUser?.message}`,
  //   }).then((message) => alert(message));
  // };

  const submitData = (e) => {
    console.log("INSIDE HANDLE SUBMIT");
    e.preventDefault();
    // call a backend API to verify reCAPTCHA response
    fetch(
      `https://tenuous-insect-production.up.railway.app/api/magpowermail/email`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: [userState?.currentUser?.email, 'info@magpower.in', 'magpowerpune@gmail.com'],
          subject: `ScroBits | We got it -- RE: ${userState?.currentUser?.message}`,
          message: `Hi ${userState?.currentUser?.fullName},<br/> <br/> Thanks so much for reaching out ! This auto-reply is just to let you know…<br/> <br/> We received your email and will get back to you with a (human) response as soon as possible. During 9:00hrs to 18:00hrs that’s usually within a couple of hours. Evenings and weekends may take us a little bit longer.<br/> <br/> If you have any additional information that you think will help us to assist you, please feel free to reply to this email.<br/> <br/> Details that you provided are as follows:<br/> Name:  ${userState?.currentUser?.fullName}<br/> EmailId: ${userState?.currentUser?.email}
          <br/> Company Name:  ${userState?.currentUser?.companyName}<br/> Phone:  ${userState?.currentUser?.phoneNumber}<br/> Query:   ${userState?.currentUser?.message}<br/> <br/> We look forward to chatting soon !<br/> <br/> Cheers,<br/> Ashpak Mokashi,<br/>
General Manager,<br/>
MagPower Pune. <br/> Phone : +918530873426.<br/> `,
          from: "magpower",
        }),
      }
    )
      .then(async (res) => {
        swal({
          icon: "success",
          title: "Done",
        });
        console.log("RES", res);
      })
      .catch((err) => {
        console.log("Error :", err);
      });

    setUserState({
      currentUser: {
        fullName: "",
        phoneNumber: "",
        email: "",
        city: "",
        message: "",
        companyName: "",
      },
    });
  };

  const handleChange_ = (e) => {
    const value = e.target.value;
    if (e.target.name === "phoneNumber") {
      if (value.length === 10) {
        setPhoneNumberAlert(false);
      } else {
        setPhoneNumberAlert(true);
      }
    }
    setUserState({
      currentUser: {
        ...userState.currentUser,
        [e.target.name]: value,
      },
    });
  };

  /*  const handleChange = (e) => {
    if (e.target.name === "fullName") {
      setUserState({
        currentUser: {
          ...userState?.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "email") {
      setUserState({
        currentUser: {
          ...userState?.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "phoneNumber") {
      setUserState({
        currentUser: {
          ...userState?.currentUser,
          [e.target.name]: e.target.value,
        },
      });
      if (userState?.currentUser?.phoneNumber?.length === 9) {
        setPhoneNumberAlert(false);
      } else {
        setPhoneNumberAlert(true);
      }
    } else if (e.target.name === "city") {
      setUserState({
        currentUser: {
          ...userState?.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "message") {
      setUserState({
        currentUser: {
          ...userState?.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    } else if (e.target.name === "companyName") {
      setUserState({
        currentUser: {
          ...userState?.currentUser,
          [e.target.name]: e.target.value,
        },
      });
    }
  }; */

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div className="contact-us-main" id="contactus">
        <div className="contact-us-title">
          <h1>Contact Us</h1>
          <svg
            width="242"
            height="40"
            viewBox="0 0 242 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="-2.18557e-07"
              y1="19.5"
              x2="53"
              y2="19.5"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="63"
              y1="19.5"
              x2="242"
              y2="19.5"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="55.5"
              y1="1.09278e-07"
              x2="55.5"
              y2="40"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="65.5"
              y1="9"
              x2="65.5"
              y2="31"
              stroke="#F0A202"
              stroke-width="5"
            />
          </svg>
        </div>
        <div className="contact-form-wrapper">
          <form className="contact-us-left-section-form" onSubmit={submitData}>
            <input
              className="contact-us-left-section-form-inputs"
              name="fullName"
              type="text"
              placeholder="Full Name*"
              value={userState?.currentUser?.fullName}
              onChange={handleChange_}
              required
            ></input>

            <input
              className="contact-us-left-section-form-inputs"
              name="companyName"
              type="text"
              placeholder="Company Name*"
              value={userState?.currentUser?.companyName}
              onChange={handleChange_}
              required
            ></input>

            <div className="contact-us-left-section-form-input-container">
              <input
                className="contact-us-left-section-form-inputs-1"
                type="number"
                name="phoneNumber"
                placeholder="Phone No*"
                value={userState?.currentUser?.phoneNumber}
                onChange={handleChange_}
                required
              ></input>
              {phoneNumberAlert ? (
                <p style={{ color: "red" }}>Invalid Phone Number</p>
              ) : (
                <div></div>
              )}
            </div>

            <input
              className="contact-us-left-section-form-inputs"
              type="email"
              name="email"
              placeholder="Email Id*"
              value={userState?.currentUser?.email}
              onChange={handleChange_}
              required
            ></input>
            <input
              className="contact-us-left-section-form-inputs"
              type="text"
              name="city"
              placeholder="City"
              value={userState?.currentUser?.city}
              onChange={handleChange_}
            ></input>

            <div className="contact-us-left-section-form-input-container">
              <input
                className="contact-us-left-section-form-inputs-1"
                type="text"
                name="message"
                placeholder="Your Message here"
                value={userState?.currentUser?.message}
                onChange={handleChange_}
              ></input>
            </div>

            <button type="submit" className="contact-us-submit-btn">
              Submit
            </button>
          </form>

          <section className="contact-us-right-section">
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">Contact</p>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-phone-alt contact-fontawesome-style"></i>

                <p className="contact-us-right-section-para">
                  Sandeep Pandit : +919850551552
                  <br />
                  Ashpak Mokashi : +918530873426
                </p>
              </div>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-envelope contact-fontawesome-style"></i>
                <p className="contact-us-right-section-para">
                info@magpower.in
                <br />
                magpowerpune@gmail.com
                </p>
              </div>
            </div>
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">Address</p>

              <div className="contact-us-right-section-row1">
                <i className="fas fa-map-marker-alt contact-fontawesome-style"></i>
                <p className="contact-us-right-section-para">
                  Office : RENUKA SMRUTI 19,Hingane Home Colony
                  Karvenagar,Pune-411052
                  <br />
                  <br />
                  Works : Shed No.2, S. No. 77/4, Vishnu-malati Industrial
                  Esate, Shivne, Pune- 411 023
                </p>
              </div>
            </div>
            <div className="contact-us-right-section-first-row">
              <p className="contact-us-right-section-subheading">
                Social Links
              </p>

              <div className="contact-us-right-section-media-container">
                
                <a href="https://www.instagram.com/mag.power.pune/" className="mr-4">
                  <i className="fab fa-instagram contact-social-media-icon"></i>
                </a>
                <a href="https://www.facebook.com/MagPower.Pune" target="_blank" className="mr-4">
                  <i className="fab fa-facebook contact-social-media-icon"></i>
                </a>
                <a href="https://youtu.be/J39WB7mkUmI" target="_blank" className="mr-4">
                  <i className="fab fa-youtube contact-social-media-icon"></i>
                </a>
                <a href="#" className="mr-4">
                  <i className="fab fa-twitter contact-social-media-icon"></i>
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
