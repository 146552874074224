import React from "react";
import { Helmet } from "react-helmet";

function SEO(props) {
  const {
    title,
    description,
    keyword,
    cardImage,
    cardDescription,
    cardTitle,
    cardUrl,
    cardType,
  } = props;
  console.log("props", props);

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword}></meta>

      <meta property="og:type" content={cardType} />
      <meta property="og:url" content={cardUrl} />
      <meta property="og:title" content={cardTitle} />
      <meta property="og:description" content={cardDescription} />
      <meta property="og:image" content={cardImage} />

      <meta property="twitter:card" content={cardType} />
      <meta property="twitter:url" content={cardUrl} />
      <meta property="twitter:title" content={cardTitle} />
      <meta property="twitter:description" content={cardDescription} />
      <meta property="twitter:image" content={cardImage}></meta>
    </Helmet>
  );
}

export default SEO;
