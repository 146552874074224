import { PauseCircle, PlayCircle } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import "../../styles/home.css";
const AutomaticSpotWelding =
  "/assets/Videos/Automatic Spot Welding Machine.m4v";
const Soldering = "/assets/Videos/Soldering.m4v";
const UltrasonicMachine = "/assets/Videos/Ultrasonic Machine.m4v";
const AutomaticVoltagTesting =
  "/assets/Videos/Automatic Voltage Testing Instrument.m4v";
function VideoSection() {
  const vidRef1 = useRef(null);
  const vidRef2 = useRef(null);
  const vidRef3 = useRef(null);
  const vidRef4 = useRef(null);
  const [pausePlayBtnState1, setPausePlayBtnState1] = useState(false);
  const [pausePlayBtnState2, setPausePlayBtnState2] = useState(false);
  const [pausePlayBtnState3, setPausePlayBtnState3] = useState(false);
  const [pausePlayBtnState4, setPausePlayBtnState4] = useState(false);

  const handlePlayVideo = (ref) => {
    if (ref === "ref1") {
      setPausePlayBtnState1(true);
      vidRef1.current.play();
    } else if (ref === "ref2") {
      setPausePlayBtnState2(true);

      vidRef2.current.play();
    } else if (ref === "ref3") {
      setPausePlayBtnState3(true);

      vidRef3.current.play();
    } else if (ref === "ref4") {
      setPausePlayBtnState4(true);

      vidRef4.current.play();
    }
  };
  const handlePauseVideo = (ref) => {
    if (ref === "ref1") {
      setPausePlayBtnState1(false);
      vidRef1.current.pause();
    } else if (ref === "ref2") {
      setPausePlayBtnState2(false);

      vidRef2.current.pause();
    } else if (ref === "ref3") {
      setPausePlayBtnState3(false);

      vidRef3.current.pause();
    } else if (ref === "ref4") {
      setPausePlayBtnState4(false);

      vidRef4.current.pause();
    }
  };
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);
  return (
    <div>
      {screenSize.dynamicWidth <= 500 ? (
        <div className="home-page-machines">
          <div className="machine-card-wrapper1">
            <div className="machines-card">
              <div className="machine-card-media">
                <div
                  style={{
                    position: "absolute",

                    left: "45%",
                    top: "40%",
                    // marginBottom: "10%",
                    zIndex: "1",
                  }}
                >
                  {pausePlayBtnState1 ? (
                    <PauseCircle
                      onClick={() => handlePauseVideo("ref1")}
                      fontSize="large"
                      htmlColor="white"
                    />
                  ) : (
                    <PlayCircle
                      color="white"
                      htmlColor="white"
                      onClick={() => handlePlayVideo("ref1")}
                      fontSize="large"
                    />
                  )}
                </div>
                <video
                  loading="lazy"
                  allowfullscreen={false}
                  alt="Automatic Spot Welding at MagPower Pune"
                  src={AutomaticSpotWelding}
                  //   onMouseOver={(event) => event.target.play()}
                  //   onMouseOut={(event) => event.target.pause()}
                  // muted
                  ref={vidRef1}
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FGroup%203345.webp?alt=media&token=091f4049-b805-4e62-af54-6b181b13aea1"
                  // autoPlay
                  // loop
                  style={{ width: "100%", objectFit: "contain" }}
                ></video>
              </div>
              <div className="machine-card-content">
                <h2>Automatic Spot Welding Machines</h2>
                <p>
                  Speed of more than than 2000 welds/hr/machine for bulk Custom
                  Battery Packs manufacturing. These machines provides Accurate
                  weldings, improved production efficiency and reduced labour
                  costs.
                </p>
              </div>
            </div>
            <div className="machines-card">
              <div className="machine-card-media">
                <div
                  style={{
                    position: "absolute",

                    left: "45%",
                    top: "40%",
                    // marginBottom: "10%",
                    zIndex: "1",
                  }}
                >
                  {pausePlayBtnState2 ? (
                    <PauseCircle
                      onClick={() => handlePauseVideo("ref2")}
                      fontSize="large"
                      htmlColor="white"
                    />
                  ) : (
                    <PlayCircle
                      color="white"
                      htmlColor="white"
                      onClick={() => handlePlayVideo("ref2")}
                      fontSize="large"
                    />
                  )}
                </div>
                <video
                  loading="lazy"
                  alt="Ultrasonic Sealing Machine at MagPower Pune"
                  src={UltrasonicMachine}
                  // muted
                  // autoplay
                  ref={vidRef2}
                  muted
                  loop
                  playsinline
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6128%201.webp?alt=media&token=402e7bbe-daab-4647-b332-31cfa0400fcf"
                  //   onMouseOver={(event) => event.target.play()}
                  //   onMouseOut={(event) => event.target.pause()}
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </div>
              <div className="machine-card-content">
                <h2>Automatic Ultrasonic Machine (Sealing Machine)</h2>
                <p>
                  High quality imported material ultrasonic transducer, strong
                  power and stable machine provides reliable and long-service
                  life non-solvent, non-chemical based Custom Battery pack
                  housings.
                </p>
              </div>
            </div>
          </div>
          <div className="machine-card-wrapper2">
            <div className="machines-card">
              <div className="machine-card-media">
                <div
                  style={{
                    position: "absolute",

                    left: "45%",
                    top: "40%",
                    // marginBottom: "10%",
                    zIndex: "1",
                  }}
                >
                  {pausePlayBtnState3 ? (
                    <PauseCircle
                      onClick={() => handlePauseVideo("ref3")}
                      fontSize="large"
                      htmlColor="white"
                    />
                  ) : (
                    <PlayCircle
                      color="white"
                      htmlColor="white"
                      onClick={() => handlePlayVideo("ref3")}
                      fontSize="large"
                    />
                  )}
                </div>
                <video
                  loading="lazy"
                  alt="Antistatic soldering station at MagPower Pune"
                  src={Soldering}
                  ref={vidRef3}
                  muted
                  //   onMouseOver={(event) => event.target.play()}
                  //   onMouseOut={(event) => event.target.pause()}
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6170%203.webp?alt=media&token=1429d49d-c23d-4b6a-b95f-455814dfe970"
                  // autoPlay
                  loop
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </div>
              <div className="machine-card-content">
                <h2>Antistatic soldering station</h2>
                <p>
                  Fleet of Skilled hand soldering technicians for quality
                  soldering of any kinds of your custom/bespoke lithium ion
                  battery pack assembly or other types of battery composition
                  assemblies.
                </p>
              </div>
            </div>
            <div className="machines-card">
              <div className="machine-card-media">
                <div
                  style={{
                    position: "absolute",

                    left: "45%",
                    top: "40%",
                    // marginBottom: "10%",
                    zIndex: "1",
                  }}
                >
                  {pausePlayBtnState4 ? (
                    <PauseCircle
                      onClick={() => handlePauseVideo("ref4")}
                      fontSize="large"
                      htmlColor="white"
                    />
                  ) : (
                    <PlayCircle
                      color="white"
                      htmlColor="white"
                      onClick={() => handlePlayVideo("ref4")}
                      fontSize="large"
                    />
                  )}
                </div>
                <video
                  loading="lazy"
                  alt="Automatic Voltage Testing at MagPower Pune"
                  ref={vidRef4}
                  src={AutomaticVoltagTesting}
                  // muted
                  hover
                  //   onMouseOver={(event) => event.target.play()}
                  //   onMouseOut={(event) => event.target.pause()}
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6020%201.webp?alt=media&token=9d53d262-028d-4fac-bb65-161f7d8916b4"
                  // autoPlay
                  // loop
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </div>
              <div className="machine-card-content">
                <h2>DC Impedence testing instrument</h2>
                <p>
                  Most accurate test set with accuracy of 0.01% to ensure
                  quality and reliablity of your battery pack assebmly.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="home-page-machines">
          <div className="machine-card-wrapper1">
            <div className="machines-card">
              <div className="machine-card-media">
                <video
                  loading="lazy"
                  allowfullscreen={false}
                  alt="Automatic Spot Welding machines at MagPower Pune"
                  src={AutomaticSpotWelding}
                  onMouseOver={(event) => event.target.play()}
                  onMouseOut={(event) => event.target.pause()}
                  // muted
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FGroup%203345.webp?alt=media&token=091f4049-b805-4e62-af54-6b181b13aea1"
                  // autoPlay
                  // loop
                  style={{ width: "100%", objectFit: "contain" }}
                ></video>
              </div>
              <div className="machine-card-content">
                <h2>Automatic Spot Welding Machines</h2>
                <p>
                  Speed of more than than 2000 welds/hr/machine for bulk Custom
                  Battery Packs manufacturing. These machines provides Accurate
                  weldings, improved production efficiency and reduced labour
                  costs.
                </p>
              </div>
            </div>
            <div className="machines-card">
              <div className="machine-card-media">
                <video
                  loading="lazy"
                  alt="Ultrasonic battery sealing at MagPower Pune"
                  src={UltrasonicMachine}
                  // muted
                  // autoplay
                  muted
                  loop
                  playsinline
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6128%201.webp?alt=media&token=402e7bbe-daab-4647-b332-31cfa0400fcf"
                  onMouseOver={(event) => event.target.play()}
                  onMouseOut={(event) => event.target.pause()}
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </div>
              <div className="machine-card-content">
                <h2>Automatic Ultrasonic Machine (Sealing Machine)</h2>
                <p>
                  High quality imported material ultrasonic transducer, strong
                  power and stable machine provides reliable and long-service
                  life non-solvent, non-chemical based Custom Battery pack
                  housings.
                </p>
              </div>
            </div>
          </div>
          <div className="machine-card-wrapper2">
            <div className="machines-card">
              <div className="machine-card-media">
                <video
                  loading="lazy" 
                  alt="MagPower Pune introduction Video"
                  src={Soldering}
                  muted
                  onMouseOver={(event) => event.target.play()}
                  onMouseOut={(event) => event.target.pause()}
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6170%203.webp?alt=media&token=1429d49d-c23d-4b6a-b95f-455814dfe970"
                  // autoPlay
                  loop
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </div>
              <div className="machine-card-content">
                <h2>Antistatic soldering station</h2>
                <p>
                  Fleet of Skilled hand soldering technicians for quality
                  soldering of any kinds of your custom/bespoke lithium ion
                  battery pack assembly or other types of battery composition
                  assemblies.
                </p>
              </div>
            </div>
            <div className="machines-card">
              <div className="machine-card-media">
                <video
                  loading="lazy"
                  alt="Automatic Voltage Testing at MagPower Pune"
                  src={AutomaticVoltagTesting}
                  // muted
                  hover
                  onMouseOver={(event) => event.target.play()}
                  onMouseOut={(event) => event.target.pause()}
                  controlsList="nodownload nofullscreen"
                  poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6020%201.webp?alt=media&token=9d53d262-028d-4fac-bb65-161f7d8916b4"
                  // autoPlay
                  // loop
                  style={{ width: "100%", objectFit: "contain" }}
                />
              </div>
              <div className="machine-card-content">
                <h2>DC Impedence testing instrument</h2>
                <p>
                  Most accurate test set with accuracy of 0.01% to ensure
                  quality and reliablity of your battery pack assebmly.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoSection;
