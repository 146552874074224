import React, { useEffect } from "react";
import "../../styles/about.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
// import HistoryImage from "../../assets/Images/IMG_6078 1.png";
import { Carousel } from "react-responsive-carousel";

const About = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Header />
      <div className="aboutus-container">
        <div className="aboutus-page-aboutus-section">
          <div className="aboutus-title">
            <h1>About Us</h1>
            <svg
              width="249"
              height="40"
              viewBox="0 0 249 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-2.18557e-07"
                y1="19.5"
                x2="53"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="63"
                y1="19.5"
                x2="249"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="55.5"
                y1="1.09278e-07"
                x2="55.5"
                y2="40"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="65.5"
                y1="9"
                x2="65.5"
                y2="31"
                stroke="#F0A202"
                stroke-width="5"
              />
            </svg>
          </div>
          <div className="aboutus-title-section">
            <h1>We are MagPower...</h1>
          </div>
        </div>
        <div className="aboutus-middle-grid-container">
          <div className="aboutus-middle-hidden-container">
            <div className="aboutus-middle-grid-item">
              <p className="aboutus-middle-grid-subTitle">Our Vision</p>
              <hr></hr>

              <p className="aboutus-middle-grid-para">
                The vision is to create a better world by providing portable
                energy products, through innovative technology and reducing
                imports.
              </p>
            </div>
          </div>
          <div className="aboutus-middle-hidden-container">
            <div className="aboutus-middle-grid-item">
              <p className="aboutus-middle-grid-subTitle">Our Mission</p>
              <hr></hr>
              <p className="aboutus-middle-grid-para">
                Mission is to deliver world-class portable power sources in a
                safe, reliable, efficient, and environmentally sound manner
              </p>
            </div>
          </div>
          <div className="aboutus-middle-hidden-container">
            <div className="aboutus-middle-grid-item">
              <p className="aboutus-middle-grid-subTitle">Our Advantage</p>
              <hr></hr>

              <p className="aboutus-middle-grid-para">
                A strong advisory panel with exceptional domain knowledge for
                product and processes. <br />
                Equipped with advanced machinery for testing and manufacturing
              </p>
            </div>
          </div>
        </div>

        <div className="aboutus-history-container">
          <h3>Our Story</h3>
          <div className="aboutus-history-grid">
            <section className="aboutus-history-left-section">
              <p>
                Mag Power was incorporated in 1992, in Pune, the Automobile
                Industrial city of Maharashtra, India. Mag Power started with
                assembly of primary as well as secondary batteries for Indian
                Defence forces and now has become well-known name in battery
                manufacturing. Mag Power started with a single product, today it
                is a multi-product battery company for the defence needs and
                customised needs of customers. Mag Power has a state of art
                design capabilities, manufacturing facility at Karvenagar and
                Shivne in Pune. Mag Power is expanding with more plants and
                independent R&D and testing facilities.
                <br />
                <br />
                Mag Power has designed, manufactured, tested and supplied custom
                battery packs, chargers to a wide range of industries, which
                includes Defence, healthcare, solar, e-mobility, homeland
                security, Oceanographic Instruments, measuring & surveillance
                equipment, for last 28 years. Use of best available quality
                material in the battery packs, Mag Power has a strong reputation
                built upon excellent service, high product quality and a
                comprehensive range of non-rechargeable and rechargeable
                batteries and chargers. It’s a one stop solution for all
                portable battery and power management needs other than lead acid
                batteries
              </p>
            </section>
            <section className="aboutus-history-right-section">
              {/* <div className="aboutus-history-image-container">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_6078%201.webp?alt=media&token=2d27d467-346b-4278-a974-6e9476ddc9dc"
                  alt="history Image"
                  loading="lazy"
                ></img>
              </div> */}
              <div
                className="about-us-carousel"
                style={{ boxShadow: " 2rem 2rem #dbe6f0, -2rem -2rem #dbe6f0" }}
              >
                <Carousel
                  autoPlay={true}
                  infiniteLoop={true}
                  interval={4000}
                  showThumbs={false}
                  showStatus={false}
                >
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_6287About%20Us%20Carousel.webp?alt=media&token=fbe79c4c-5932-4a14-843b-122d9f96e6af"
                    alt="advanceMachinaries"
                  />
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_6087About%20Us%20Carousel.webp?alt=media&token=31685981-28fe-404b-b23a-b69b924d7cc4"
                    alt="zeroDefects"
                  />
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2Fkn%20teamAbout%20Us%20Carousel.webp?alt=media&token=4d248720-8bad-4bb2-a254-2ae42e0b4cdf"
                    alt="QualityAssurance"
                  />
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_5730About%20Us%20Carousel.webp?alt=media&token=8f87e70f-15ae-46ad-ad7c-67d5f9af008e"
                    alt="QualityAssurance"
                  />
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_5650.webp?alt=media&token=d7fc3ead-6228-474f-9641-45c48b9ef460"
                    alt="QualityAssurance"
                  />
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_5666.webp?alt=media&token=1ff027d9-2a6b-44b3-9752-4f7bd3e6ff2a"
                    alt="QualityAssurance"
                  />
                </Carousel>
              </div>
            </section>
          </div>

          <div className="aboutus-history-grid">
            <section className="aboutus-history-right-section">
              <div className="about-us-carousel">
                <img
                  alt="about us"
                  loading="lazy"
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_5605%202.webp?alt=media&token=018e968d-9cdc-4c56-85eb-d16e6ca02819"
                  style={{
                    width: "80%",
                    boxShadow: " 2rem 2rem #dbe6f0, -2rem -2rem #dbe6f0",
                  }}
                />
              </div>
            </section>
            <section className="aboutus-history-left-section">
              <div className="about-us-history-subheading-container">
                <span className="about-us-history-subheading">
                  Mag Power is committed to
                </span>
                <hr />
              </div>
              <p>
                Continuously improvement in QM system and monitor its
                efficiency. Execute our business processes in an effective and
                efficient manner. Set up and sustain intensive and satisfying
                business relationships with suppliers.
                <br />
                Advise and support our customers through competent employees.
                Motivate and train our employees. To monitor our quality policy
                through measurable quality goals. To analyse the risks and
                opportunities to ensure its continued existence. We have
                undergone 5S and Lean Management training. We certified and
                periodically assessed for effective implementation and pursuit.
              </p>
            </section>
          </div>

          <div className="aboutus-blue-history-grid">
            <section className="aboutus-blue-history-left-section">
              <div className="about-us-blue-history-subheading-container">
                <span className="about-us-blue-history-subheading">
                  Capacities
                </span>
                <hr />
              </div>
              <p>
                Battery production: 60 MW/pa
                <br />
                Testing: 10 MW/pa
                <br />
                Major share is from Defence customers. Testing facilities
                <br />
                available with Defence customer.
              </p>
            </section>
          </div>

          <div className="aboutus-third-grid">
            <section className="aboutus-third-right-section">
              <div className="about-us-third-subheading-container">
                <span className="about-us-third-subheading">
                  Areas Of Supply
                </span>
                <hr />
              </div>
              <p>
                <ul>
                  <li>Standard and custom Smart batteries</li>
                  <li>Zinc carbon customised batteries</li>
                  <li>Alkaline manganese batteries</li>
                  <li>Lithium non-rechargeable (LiSO2, LiMnO2, and LiSOCl2)</li>
                  <li>Lithium rechargeable Li ion, Lithium iron phosphate</li>
                  <li>Nickel Metal Hydride (Ni-MH) batteries</li>
                  <li>Nickel Cadmium (Ni-Cd) batteries</li>
                </ul>
              </p>
            </section>
            <section className="aboutus-third-left-section">
              <div
                className="about-us-third-subheading-container"
                style={{ marginTop: "5%" }}
              >
                <span className="about-us-third-subheading">Applications</span>
                <hr />
              </div>

              <p style={{ marginTop: "10%" }}>
                <ul>
                  <li>Energy storage systems</li>
                  <li>Solar street lights</li>
                  <li>Telecom towers</li>
                  <li>Medical device instruments.</li>
                  <li>Electric vehicles</li>
                  <li>Portable Electronic Gadgets</li>
                  <li>Defence Aviation, Army & Marine.</li>
                </ul>
              </p>
            </section>
          </div>

          <div className="about-us-light-blue-container">
            <div className="aboutus-history-grid">
              <section className="aboutus-history-left-section">
                <div className="about-us-history-subheading-container">
                  <span className="about-us-history-subheading">
                    Our Products
                  </span>
                  <hr />
                </div>
                <p>
                  We produce primary as well as secondary batteries of 1S-32S
                  Battery Packs with more than 500 types. We use advanced
                  Lithium Technology that includes BMS with SMBUS (I2C, HDQ
                  Port) having large varieties. These can be broadly applied to
                  a wide variety of battery packs. Being well-equipped and
                  efficient enough in the field of Lithium technology, we
                  provide certified Lithium-ion batteries. All our products have
                  gone through a defined production and quality assurance
                  process to ensure that they perform for long life with desired
                  results. Besides the lithium-ion batteries, our smart Battery
                  Management System (BMS) is specially designed to recharge the
                  batteries and enhance their performance and efficiency for
                  optimal usage. Our Lithium-ion batteries in India are
                  available in great varieties to provide efficient energy
                  solution.
                </p>
              </section>
              <section className="aboutus-history-right-section">
                <div className="about-us-carousel">
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FCustom%20Solutions.webp?alt=media&token=0c5446bd-bf03-4c48-b36d-687ee3c7f7ae"
                    style={{
                      width: "80%",
                      boxShadow: "2rem 2rem #dbe6f0, -2rem -2rem #dbe6f0",
                    }}
                  />
                </div>
              </section>
            </div>
            <div className="aboutus-history-grid">
              <section className="aboutus-history-right-section">
                <div className="about-us-carousel">
                  <img
                    loading="lazy"
                    src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fabout-us%2FIMG_0363%20(1)%201.webp?alt=media&token=e0f3758d-3bca-429c-b679-a3855f84ac19"
                    style={{
                      width: "80%",
                      boxShadow: "2rem 2rem #dbe6f0, -2rem -2rem #dbe6f0",
                    }}
                  />
                </div>
              </section>
              <section className="aboutus-history-left-section">
                <div className="about-us-history-subheading-container">
                  <span className="about-us-history-subheading">
                    New Products
                  </span>
                  <hr />
                </div>
                <p>
                  The recent introduction of electric vehicle batteries produced
                  by Mag Power vary from e-Bicycles battery, e-Rickshaw battery,
                  and e-Scooters/e-Motorcycles battery. It is a high-performance
                  module which is built using the cell holding bracket while the
                  current sharing design has inbuilt voltage insulation. This
                  product is going to feature a long-life cycle,
                  high-performance cell with high discharge rate, high-energy
                  density, and fuse-protection. These features would improve the
                  performance of battery. The approval process is through a
                  series of tests like vibration, nail penetration,
                  high-temperature aging, etc. It will also be water-resistant,
                  dust-proof, fire retardant, CAN-compatible and has the
                  capability to be configured from vehicle to vehicle
                  accordingly. We would provide a wide range of customized
                  solutions for Lithium Batteries, Lithium-ion Cells, and
                  Battery Packs for electric vehicles like eScooter battery,
                  eCycle battery and more, solar lighting & energy storage,
                  medical & other devices. Our Lithium solutions are based on
                  NMC, LMO, LFP, LCO chemistries providing energy extendable for
                  other applications like portable storage devices, UPS
                  inverters, power banks, handheld searchlights, etc.
                </p>
              </section>
            </div>
          </div>
          <div className="about-us-our-strengths-wrapper">
            <div className="about-us-blue-history-subheading-container">
              <span className="about-us-blue-history-subheading">
                Our Strengths
              </span>
              <hr />
            </div>
            <div className="about-us-our-strengths-para">
              <p>
                <ul>
                  <li>
                    A strong advisory panel with exceptional domain knowledge
                    for product and processes.
                  </li>
                  <li>
                    Micro, Small and Medium Enterprises (MSME), registered
                    company.
                  </li>
                  <li>
                    Registered vendor of Govt. agencies such as DRDO, COD, BEL,
                    C-DAC, NIO, NIOT, NCSCM, etc.
                  </li>
                  <li>ISO 9001:2015 certified.</li>
                  <li>
                    Our cells used in the batteries are BIS approved and the raw
                    material is of high quality.
                  </li>
                  <li>
                    Our Customer Support Services are highly satisfactory.
                  </li>
                  <li>
                    We have capability and industry expertise and experience
                    when it comes to meeting the distinctive needs of customers.
                    We cater from a low volume to a high quantum of production
                    with our outstanding technical skilled team that is capable
                    enough to design and manufacture conventional battery
                  </li>
                  <li>
                    solutions for Lithium ion batteries and integrated systems.
                  </li>
                  <li>
                    Product designing is done by following the strict cell
                    grading and sorting process, ensuring the voltage and
                    current protection right at preliminary level.
                  </li>
                  <li>
                    Equipped with advanced machinery for testing and
                    manufacturing.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="about-us-production-wrapper">
            <div className="about-us-history-subheading-container">
              <span
                className="about-us-history-subheading"
                style={{ marginLeft: "20%" }}
              >
                Production, Testing and R&D
              </span>
              <hr style={{ width: "20%" }} />
            </div>
            <div className="about-us-production-para">
              <p>
                Magpower has vast in-house production, inspection, and research
                & development facilities to design, develop, and produce Lithium
                energy storage solutions. We are equipped to provide
                fully-customized solutions as per the client’s requirement. All
                our products successfully pass through stringent quality and
                aging checks to deliver optimum results with possible long life.
                The setup of installed machinery in production, testing and R&D
                is procured from the reputed manufacturers. <br />
                <br />
                <b>1. Manufacturing equipment</b> <br />
                <div className="about-us-first-table-wrappeer">
                  <table>
                    <tr>
                      <td>a. Spot welding machines</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manual Spot welding</td>
                      <td>19 no</td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fully automatic battery
                        spot welding machine
                      </td>
                      <td>2 No</td>
                    </tr>
                    <tr>
                      <td>b. Cell testing and sorting machine proposed</td>
                      <td>1 no</td>
                    </tr>
                    <tr>
                      <td>c. Cell grading machine proposed</td>
                      <td>2 no</td>
                    </tr>
                    <tr>
                      <td>d. Cell grouping and stacking benches</td>
                      <td>20 no</td>
                    </tr>
                    <tr>
                      <td>e. Soldering stations</td>
                      <td>10 no</td>
                    </tr>
                    <tr>
                      <td>
                        f. Ultrasonic sealing machine for plastic box sealing
                      </td>
                      <td>1 no</td>
                    </tr>
                    <tr>
                      <td>g. Wire cutting and stripping machine</td>
                      <td>2 no</td>
                    </tr>
                    <tr>
                      <td>h. Nickel strip cutting machine</td>
                      <td>1 no</td>
                    </tr>
                    <tr>
                      <td>i. Oscilloscope</td>
                      <td>1 no</td>
                    </tr>
                    <tr>
                      <td>j. Multimeters</td>
                      <td>10 no</td>
                    </tr>
                    <tr>
                      <td>k. Discharge resistances</td>
                      <td>various</td>
                    </tr>
                    <tr>
                      <td>l. Riveting machine</td>
                      <td>2 no</td>
                    </tr>
                    <tr>
                      <td>m. Drilling machine</td>
                      <td>1 no</td>
                    </tr>
                    <tr>
                      <td>n. Solder bath</td>
                      <td>1 no</td>
                    </tr>
                    <tr>
                      <td>o. Hot air blowers</td>
                      <td>5 no</td>
                    </tr>
                  </table>
                </div>
              </p>
            </div>
            <div className="about-us-production-list-wrapper">
              <p>
                <b>2.Testing Equipments</b>
                <br /> <br />
                a. Cell inspection multimeters <br />
                b. Regulated DC power supply Battery Discharge <br />
                c. Insulation resistance tester-Hikyo Japan <br />
                d. Cell capacity measurement –outsourced <br />
                e. Battery discharger 12v -72V 1 no Nov proposed <br />
                f. Battery Internal Impedance meter 1 no. <br />
                <br />
                <b>3. Jigs and Fixtures:</b>
                <br />a full-fledged machine shop is available for manufacturing
                required jogs and fixtures and machinery for enhancing
                production capability. <br />
                <br />
                <b>4. Automation</b>
                <br />
                Developed and manufactured an automatic spot welding machine
                This has improved productivity to meet urgent deliveries.
                <br />
                <br />
                <b>5. Manpower</b>
                <br />
                Dedicated and well trained manpower for each operation for
                manufacturing and testing of the batteries is available in
                Magpower. Ongoing efforts are put to improve skill and
                productivity of the people by introducing various schemes like
                incentive schemes.
                <br />
                <br />
                <b>6. Design and Engineering:</b>
                <br />
                a. Strong support from Institutions <br />
                b. In-house design and Development capabilities <br />
                <br />
                <b>7. Manufacturing and Quality assurance</b>
                <br />
                a. Good material procurement practices from approved vendors.{" "}
                <br />
                b. Process approach <br />
                c. All process parameters approved by Customers <br />
                d. Training the operators for the processes <br />
                e. Incoming quality assurance for materials. <br />
                f. In process inspection for consistency of production quality.
                <br />
                g. Final quality assurance process. <br />
                h. Finish goods inspection before despatch. <br />
                i. Process improvement depending on customer feedbacks. <br />
                j. Adherence to technical parameters & quality standards. <br />
                k. Systematic procedures & compliance. <br />
                l. Audits by Defence inspection agencies for processes and
                material quality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
