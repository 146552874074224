import React from "react";

const Demo = () => {
  const backGround =
    "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/videos%2F2%20Mag%20power%20main%20video%2060fps.mp4?alt=media&token=7b1f9fcd-56a3-4be7-b90a-a5e4bf316896";
  return (
    <div>
      <h1>hello</h1>
      <video
        src={backGround}
        muted
        controls
        autoPlay
        loop
        style={{ width: "100%", objectFit: "contain" }}
      ></video>
      {/* <video
        src={backGround}
        muted
        controls
        autoPlay
        loop
        style={{ width: "100%", height:"100%", objectFit: "cover" }}
      ></video> */}
      <div style={{position:"absolute", width:"100%", height:"100%", top:"0", display:"flex", flexDirection:"column" }}>Welcome</div>
    </div>
  );
};

export default Demo;
