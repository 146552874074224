import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import "../../styles/Products.css";
// import AutomotiveBattery from "../../assets/Images/AutomotiveBattery.png";
// import EVSBattery from "../../assets/Images/EVSBattery.png";
import Footer from "../../components/footer/Footer";
import { useNavigate, useParams } from "react-router-dom";

function Products() {
  const AutomotiveBattery =
    "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FAutomotiveBattery.webp?alt=media&token=645473cb-b2b3-4556-b905-c80c1caf8603";
  const EVSBattery =
    "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FEVSBattery.webp?alt=media&token=c54f7706-3982-4aa6-a92c-8f4aeabf6685";
  const [industryBatteries, setIndustryBatteries] = useState([
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FDigital%20Water%20Level%20Meter.webp?alt=media&token=1f1efef5-d936-46b7-9c70-55cbd65a5e49",
      name: "Digital Water Level Meter",
      voltage: "13.5V, 15AH",
      use: "Industries",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FUPS%20Battery(Used%20in%20Lift)%2060V%2090AH.webp?alt=media&token=dccfa42e-f072-4fa6-ad6a-bb1c54a08f78",
      name: "UPS Battery (use In Lift)",
      voltage: "60V, 90AH",
      use: "Industries",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FNIMH%2018V%204.5AH.webp?alt=media&token=ab12a9ed-18f7-48ce-82b8-4b2edef9b5fc",
      name: "NIMH",
      voltage: "18V, 4.5AH",
      use: "Industries",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FLithium%20Battery%2014.8V%207.8AH.webp?alt=media&token=791591b6-d14b-4a67-b534-264f5c5e7966",
      name: "Lithium Battery",
      voltage: "14.8V, 7.8AH",
      use: "Industries",
    },
  ]);

  const [evBatteries, setEvBatteries] = useState([
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FAuto%20Rickshaw%20Battery(Lithium)%203%20Wheeler%20Auto%2060V%2070AH.webp?alt=media&token=67e67270-2417-4c82-b6d9-ca75e48391c9",
      name: "Auto Rickshaw Battery (Lithium)",
      voltage: "60V, 70AH",
      use: "Electric Vehicles",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FAuto%20Rickshaw%20Battery(Lithium)%203%20Wheeler%20Auto%2055.5V%20160Ah.webp?alt=media&token=454a395f-61f8-46d5-b64b-b36219327b1a",
      name: "Auto Rickshaw Battery (Lithium)",
      voltage: "55.5V, 160AH",
      use: "Electric Vehicles",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2F2%20Wheeler%20Battery%2060V%2030AH.webp?alt=media&token=afb87ed8-5f6c-4afc-9b29-e5ccfb331301",
      name: "2 Wheeler Battery",
      voltage: "60V, 30AH",
      use: "Electric Vehicles",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FEV%20Battery%20with%20Smart%20BMS%2060V%2070AH.webp?alt=media&token=6c06faf8-be4f-47c1-a403-45a8611002c0",
      name: "EV Battery with Smart BMS",
      voltage: "60V, 70AH",
      use: "Electric Vehicles",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FEV%20Battery%20(Auto%20Rikshaw)%2055.5V%2080AH.webp?alt=media&token=c7b78b41-a155-4428-a215-b1fab8fc597a",
      name: "EV Battery (Auto Rikshaw)",
      voltage: "55.5V, 80AH",
      use: "Electric Vehicles",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FEV%20Battery%20(3%20Wheeler%20Handicap%20Bike)%2025.9V%2016.8AH.webp?alt=media&token=edf6960e-cbde-4092-88cd-dae6eb9e9ed2",
      name: "EV Battery ( 3 Wheeler Handicap Bike )",
      voltage: "25.9V, 16.8AH",
      use: "Electric Vehicles",
    },
  ]);

  const [oceanbatteries, setOceamBatteries] = useState([
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FADCP%20Acoustic%20Doppler%20Current%20Profiler%2042V%2015AH%20Alkaline%20Battery%202.webp?alt=media&token=66b07e4c-ee1c-43bb-9140-76de8c82da92",
      name: "ADCP (Acoustic Doppler Current Profiler)",
      voltage: "42V, 15AH",
      use: "Oceans",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FRIZK%20Acoustic%2027V%2015AH.webp?alt=media&token=c540e230-f727-413d-8198-536db4e7262c",
      name: "8. RIZK Acoustic",
      voltage: "27V, 15AH",
      use: "Oceans",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FRCM%20Battery%209V%205AH%202.webp?alt=media&token=dc260f10-0258-4829-9188-3cfaf795f030",
      name: "RCM Battery",
      voltage: "9V, 5AH",
      use: "Oceans",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FDVS%20Doppler%20Volume%20Sampler%2018V%2015AH.webp?alt=media&token=8f9c5629-70db-425c-9854-684581a033cf",
      name: "DVS (Doppler Volume Sampler)",
      voltage: "18V, 15AH",
      use: "Oceans",
    },
  ]);
  const [defenceBat, setDefenceBat] = useState([
    {
      image: "/assets/BFSR.png",
      name: "BFSR (battle Field Surveillance Radar ",
      voltage: "25.8V, 11AH",
      use: "Defence Systems",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FPBG%20Mine%20Battery%20(MultiVoltage).webp?alt=media&token=c4593ad7-a53c-48fc-897c-c9721e6e6335",
      name: "PBG Mine Battery (MultiVoltage)",
      voltage: "+18V, -18V, +7V",
      use: "Defence Systems",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FLUP%20322%20Battery%2024V%205AH.webp?alt=media&token=425beac9-7850-419f-b321-c8e9dcfcdf4f",
      name: "LUP 322 Battery",
      voltage: "24V, 5AH",
      use: "Defence Systems",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fproducts%2FRadar%20Battery%2024V%2090AH.webp?alt=media&token=01a329d3-aef6-43e1-9acc-8f80dd8d98a2",
      name: "Radar Battery",
      voltage: "24V, 90Ah",
      use: "Defence Systems",
    },
  ]);

  const [type, setType] = useState("industrial");
  const [selected, setSelected] = useState([]);
  const batteryType = useParams().name;
  const navigate = useNavigate();
  const selectCategory = (type) => {
    switch (type) {
      case "industrial":
        setSelected(industryBatteries);
        break;

      case "ev":
        setSelected(evBatteries);
        break;

      case "ocean":
        setSelected(oceanbatteries);
        break;
      case "defence":
        setSelected(defenceBat);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setType(batteryType);
    selectCategory(batteryType);
  }, []);

  return (
    <div>
      <Header />
      <div className="products-page">
        <div className="products-page-container">
          <div className="products-page-title">
            <h1
              style={{
                color: "white",
                fontSize: "xx-large",
                textAlign: "center",
              }}
            >
              Products
            </h1>
            <svg
              width="230"
              height="40"
              viewBox="0 0 249 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-2.18557e-07"
                y1="19.5"
                x2="53"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="63"
                y1="19.5"
                x2="249"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="55.5"
                y1="1.09278e-07"
                x2="55.5"
                y2="40"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="65.5"
                y1="9"
                x2="65.5"
                y2="31"
                stroke="#F0A202"
                stroke-width="5"
              />
            </svg>
          </div>
          <div className="product-page-categories">
            <div className="categories-title">
              <h4>Product Categories</h4>
            </div>
            <div className="category-select">
              <div
                className={
                  type === "ev"
                    ? "category-batteries category-batteries-selected"
                    : "category-batteries"
                }
                onClick={() => {
                  selectCategory("ev");
                  setType("ev");
                }}
              >
                <p>EV Batteries</p>
              </div>
              <div
                className={
                  type === "industrial"
                    ? "category-batteries category-batteries-selected"
                    : "category-batteries"
                }
                onClick={() => {
                  selectCategory("industrial");
                  setType("industrial");
                }}
              >
                <p>Industrial Batteries</p>
              </div>
              <div
                className={
                  type === "ocean"
                    ? "category-batteries category-batteries-selected"
                    : "category-batteries"
                }
                onClick={() => {
                  selectCategory("ocean");
                  setType("ocean");
                }}
              >
                <p>Oceanography Batteries</p>
              </div>
              <div
                className={
                  type === "defence"
                    ? "category-batteries category-batteries-selected"
                    : "category-batteries"
                }
                onClick={() => {
                  selectCategory("defence");
                  setType("defence");
                }}
              >
                <p>Defence Batteries</p>
              </div>
            </div>
          </div>
        </div>

        <div className="products-page-list">
          {selected.map((battery) => {
            return (
              <>
                <div className="product-list-card">
                  <div className="product-img">
                    <img
                      src={battery.image}
                      alt="AutomotiveBattery"
                      loading="lazy"
                    />
                  </div>
                  <div className="product-content">
                    <h5>{battery.name}</h5>
                    <p>
                      <span>Voltage :</span> {battery?.voltage}
                    </p>
                    <p>
                      <span>Battery Use :</span> {battery?.use}
                    </p>
                  </div>
                </div>
                {/* <div
                  className="product-list-card"
                >
                  <div className="product-call-to-action-container">
                    <button>Call To Action</button>
                  </div>
                </div> */}
              </>
            );
          })}
        </div>
      </div>

      <div className="homepage-last-cta">
        <h2>
          The Assembly Project we are most proud of your project
          <span> Lets start building with us</span>
        </h2>

        <button onClick={() => navigate("/contact")}>Contact US</button>
      </div>
      <Footer />
    </div>
  );
}

export default Products;
