import firebase from "firebase/compat/app";
import "firebase/compat/storage";
const firebaseConfig = {
  apiKey: "AIzaSyCWVRTWGDSI4BRjeL51ThMkNx0iIs4YbZE",
  authDomain: "magpower-site.firebaseapp.com",
  projectId: "magpower-site",
  storageBucket: "magpower-site.appspot.com",
  messagingSenderId: "526930890761",
  appId: "1:526930890761:web:c38fb997c5b26043daa8d8",
  measurementId: "G-LVT7HTZLY4",
};

const firebase_ = firebase.initializeApp(firebaseConfig);
export const storageRef = firebase_.storage().ref("/images/image-gallery");

export default firebase_;
