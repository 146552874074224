import React, { useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Parallax, useParallax } from "react-scroll-parallax";

import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import SEO from "../../components/seo/SEO";
import "../../styles/home.css";
import { errorHandle } from "../../utilities/erroHandle/Errorhandle";
import seoData from "../../data/SEOData.json";
import Hero from "../../components/hero/Hero";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Testimonials from "../../components/testimonial/Testimonial";

import ContactUs from "../../components/contactus/ContactUs";
import { PauseCircle, PlayCircle } from "@mui/icons-material";
import VideoSection from "../../components/video/VideoSection";

const AutomaticSpotWelding =
  "/assets/Videos/Automatic Spot Welding Machine.m4v";
const Soldering = "/assets/Videos/Soldering.m4v";
const UltrasonicMachine = "/assets/Videos/Ultrasonic Machine.m4v";
const AutomaticVoltagTesting =
  "/assets/Videos/Automatic Voltage Testing Instrument.m4v";
const defence = "/assets/defence.png";
const whyUsCardCorner = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5 1V23.5H1.06486C-0.131677 23.5 15.5231 8.5 23.5 1Z"
      fill="#F0A202"
      stroke="black"
      stroke-width="0.1"
    />
  </svg>
);

function Home() {
  const backGround =
    "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/videos%2F2%20Mag%20power%20main%20video%2060fps.mp4?alt=media&token=7b1f9fcd-56a3-4be7-b90a-a5e4bf316896";
  let navigate = useNavigate();
  const myRef = useRef(null);
  const [test, setTest] = useState("");
  const [pausePlayBtnState, setPausePlayBtnState] = useState(false);
  const { ref } = useParallax({ speed: 10 });

  // video play pause ref
  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    setPausePlayBtnState(true);
    vidRef.current.play();
  };
  const handlePauseVideo = () => {
    setPausePlayBtnState(false);

    vidRef.current.pause();
  };

  const [test2, setTest2] = useState("");
  useEffect(() => {
    console.log("Test changed");
  }, [test]);
  const videoStop = () => {};
  const handleChange = (e) => {
    if (e.target.name === "test") {
      setTest(e.target.value);
    } else if (e.target.name === "test2") {
      setTest2(e.target.value);
    }
  };

  const handleNavigate = () => {
    navigate("/contact");
    // scrollTo('/')
  };
  const [showPerPage, setShowPerPage] = useState(12);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });
  const [images, setImages] = useState([
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FTeam.webp?alt=media&token=724c4eb6-9998-44c6-b8a6-dfe2ba9ec212",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FMainProduction.webp?alt=media&token=563cf1f2-22ac-4744-b513-8a5e4144d6c1",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FPreAssemblyFacility.webp?alt=media&token=0c3f3e45-74a9-441e-bd04-ce59b5913dd6",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FTrainingSession.webp?alt=media&token=48633323-ee77-4565-9e2a-1d8095d722cd",
    },
  ]);
  useEffect(() => {
    console.log(
      "Console added just to have some user interaction with DOM, dont remove this console"
    );
  }, []);
  const [model, setModel] = useState(false);
  const [tempimage, setTempimage] = useState("");
  const getImg = (image) => {
    setTempimage(image);
    setModel(true);
  };
  return (
    <div>
      <Header />
      <SEO
        title={seoData.home.english.title}
        description={seoData.home.english.description}
        keyword={seoData.home.english.keyword}
        cardTitle={seoData.home.english.cardTitle}
        cardImage={seoData.home.english.cardImage}
        cardDescription={seoData.home.english.cardDescription}
        cardUrl={seoData.home.english.cardUrl}
        cardType={seoData.home.english.cardType}
      />
      {/* <a href="#home-page-trusted-by-section">home</a> */}
      <Hero />

      <section
        id="home-page-trusted-by-section"
        className="home-page-trusted-by-section"
        ref={ref}
      >
        <Parallax>
          <div className="trusted-by-title">
            <h1>Trusted By</h1>
            <svg
              width="249"
              height="40"
              viewBox="0 0 249 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-2.18557e-07"
                y1="19.5"
                x2="53"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="63"
                y1="19.5"
                x2="249"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="55.5"
                y1="1.09278e-07"
                x2="55.5"
                y2="40"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="65.5"
                y1="9"
                x2="65.5"
                y2="31"
                stroke="#F0A202"
                stroke-width="5"
              />
            </svg>
          </div>
          <div className="trusted-by-companies">
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2Fbharat-electronics-logo-black.webp?alt=media&token=506ebd78-c025-490d-b889-ff28570bef5f"
              alt="BharatElectronicsBlack"
            />
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2FNCPOR%20logo.webp?alt=media&token=b3c46740-ad75-40cf-95c4-4ec2a43bdabe"
              alt="NCPOR"
            />
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2FNCSCM%20logo.webp?alt=media&token=c52c8299-fab8-45d1-bcc8-690cbfdf4986"
              alt="NCSCM"
            />
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2FNIO%20logo.webp?alt=media&token=b031c355-6312-4392-8a3c-dd8c420892d6"
              alt="NIOT"
            />
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2FNIOT%20logo.webp?alt=media&token=07290a1f-5118-48ff-8003-0995e1d27ff1"
              alt="BharatElectronicsBlack"
            />
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2FProvince%20power%20system%20logo.webp?alt=media&token=28bdf464-a139-465c-b23d-4b494fbb1fc4"
              alt="Province"
            />
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2Faccel%20systems%20logo.webp?alt=media&token=0192a087-31dd-429f-8fb1-4f9c520df892"
              alt="accel"
            />
            <img
              loading="lazy"
              src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Ftrusted-by%2Fbulfro-logo.webp?alt=media&token=6ddc0f45-9d82-482e-8508-3e4ad33521fd"
              alt="bulfro"
            />
          </div>
        </Parallax>
      </section>
      <section className="our-facilities-title" id="our-facilities-title">
        <h1>Our Facilities</h1>
        <svg
          width="249"
          height="40"
          viewBox="0 0 249 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="-2.18557e-07"
            y1="19.5"
            x2="53"
            y2="19.5"
            stroke="#F0A202"
            stroke-width="5"
          />
          <line
            x1="63"
            y1="19.5"
            x2="249"
            y2="19.5"
            stroke="#F0A202"
            stroke-width="5"
          />
          <line
            x1="55.5"
            y1="1.09278e-07"
            x2="55.5"
            y2="40"
            stroke="#F0A202"
            stroke-width="5"
          />
          <line
            x1="65.5"
            y1="9"
            x2="65.5"
            y2="31"
            stroke="#F0A202"
            stroke-width="5"
          />
        </svg>
      </section>
      <VideoSection />
      {/* <div className="home-page-machines">
        <div className="machine-card-wrapper1">
          <div className="machines-card">
            <div className="machine-card-media">
              <div
                style={{
                  position: "absolute",

                  left: "50%",
                  top: "50%",
                  // marginBottom: "10%",
                  zIndex: "1",
                }}
              >
                {pausePlayBtnState ? (
                  <PauseCircle
                    onClick={handlePauseVideo}
                    fontSize="large"
                    htmlColor="white"
                  />
                ) : (
                  <PlayCircle
                    color="white"
                    htmlColor="white"
                    onClick={handlePlayVideo}
                    fontSize="large"
                  />
                )}
              </div>
              <video
                loading="lazy"
                allowfullscreen={false}
                alt="Image ALt"
                src={AutomaticSpotWelding}
                onMouseOver={(event) => event.target.play()}
                onMouseOut={(event) => event.target.pause()}
                // muted
                ref={vidRef}
                controlsList="nodownload nofullscreen"
                poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FGroup%203345.webp?alt=media&token=091f4049-b805-4e62-af54-6b181b13aea1"
                // autoPlay
                // loop
                style={{ width: "100%", objectFit: "contain" }}
              ></video>
            </div>
            <div className="machine-card-content">
              <h2>Automatic Spot Welding Machines</h2>
              <p>
                Speed of more than than 2000 welds/hr/machine for bulk Custom
                Battery Packs manufacturing. These machines provides Accurate
                weldings, improved production efficiency and reduced labour
                costs.
              </p>
            </div>
          </div>
          <div className="machines-card">
            <div className="machine-card-media">
              <video
                loading="lazy"
                alt="Image ALt"
                src={UltrasonicMachine}
                // muted
                // autoplay
                muted
                loop
                playsinline
                controlsList="nodownload nofullscreen"
                poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6128%201.webp?alt=media&token=402e7bbe-daab-4647-b332-31cfa0400fcf"
                onMouseOver={(event) => event.target.play()}
                onMouseOut={(event) => event.target.pause()}
                style={{ width: "100%", objectFit: "contain" }}
              />
            </div>
            <div className="machine-card-content">
              <h2>Automatic Ultrasonic Machine (Sealing Machine)</h2>
              <p>
                High quality imported material ultrasonic transducer, strong
                power and stable machine provides reliable and long-service life
                non-solvent, non-chemical based Custom Battery pack housings.
              </p>
            </div>
          </div>
        </div>
        <div className="machine-card-wrapper2">
          <div className="machines-card">
            <div className="machine-card-media">
              <video
                loading="lazy"
                alt="Image ALt"
                src={Soldering}
                muted
                onMouseOver={(event) => event.target.play()}
                onMouseOut={(event) => event.target.pause()}
                controlsList="nodownload nofullscreen"
                poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6170%203.webp?alt=media&token=1429d49d-c23d-4b6a-b95f-455814dfe970"
                // autoPlay
                loop
                style={{ width: "100%", objectFit: "contain" }}
              />
            </div>
            <div className="machine-card-content">
              <h2>Antistatic soldering station</h2>
              <p>
                Fleet of Skilled hand soldering technicians for quality
                soldering of any kinds of your custom/bespoke lithium ion
                battery pack assembly or other types of battery composition
                assemblies.
              </p>
            </div>
          </div>
          <div className="machines-card">
            <div className="machine-card-media">
              <video
                loading="lazy"
                alt="Image ALt"
                src={AutomaticVoltagTesting}
                // muted
                hover
                onMouseOver={(event) => event.target.play()}
                onMouseOut={(event) => event.target.pause()}
                controlsList="nodownload nofullscreen"
                poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FIMG_6020%201.webp?alt=media&token=9d53d262-028d-4fac-bb65-161f7d8916b4"
                // autoPlay
                // loop
                style={{ width: "100%", objectFit: "contain" }}
              />
            </div>
            <div className="machine-card-content">
              <h2>DC Impedence testing instrument</h2>
              <p>
                Most accurate test set with accuracy of 0.01% to ensure quality
                and reliablity of your battery pack assebmly.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/*  <div className="hero-description-contact-btn">
        <button onClick={() => handleNavigate()} style={{ cursor: "pointer" }}>
          Reach Out To Us
        </button>
      </div> */}
      <Parallax translateX={[0, 0]}>
        <div className="home-page-custom-products">
          <section
            className="custom-products-title"
            id="custom-products-title"
            // ref={ref}
          >
            <h1>Custom Products delivered by MagPower</h1>
            <svg
              width="249"
              height="40"
              viewBox="0 0 249 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-2.18557e-07"
                y1="19.5"
                x2="53"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="63"
                y1="19.5"
                x2="249"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="55.5"
                y1="1.09278e-07"
                x2="55.5"
                y2="40"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="65.5"
                y1="9"
                x2="65.5"
                y2="31"
                stroke="#F0A202"
                stroke-width="5"
              />
            </svg>
          </section>
          <div className="custom-products-list">
            {/* <Parallax translateX={[20, -40]} translateY={[100, -40]}> */}
            <Link
              to="/products/ev"
              className="custom-product-card"
              data-aos-delay="50"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-mirror="true"
              data-aos-anchor-placement="top-center"
            >
              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fcustom-products-delivered-by-magpower%2FGroup%203355.webp?alt=media&token=6135356a-fe91-4575-93d6-79615f026514"
                alt="EV Battery Assembly at Magpower Pune"
              />
              {/* <p className="custom-product-card-p1">Electric Vehicle Battries</p> */}
            </Link>
            {/* </Parallax> */}
            {/* <Parallax translateX={[20, -20]} translateY={[100, -40]}> */}
            <Link
              to="/products/industrial"
              className="custom-product-card"
              data-aos-delay="50"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-mirror="true"
              data-aos-anchor-placement="top-center"
            >
              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fcustom-products-delivered-by-magpower%2FGroup%203356.webp?alt=media&token=efe86fd8-ec50-404c-a37b-7b07e5d2642e"
                alt="Industrial Applications Batteries Assembly at Magpower Pune"
              />
              {/* <p className="custom-product-card-p2">Industrial Batteries</p> */}
            </Link>
            {/* </Parallax> */}

            <Link
              to="/products/ocean"
              className="custom-product-card"
              data-aos-delay="50"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-mirror="true"
              data-aos-anchor-placement="top-center"
            >
              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fcustom-products-delivered-by-magpower%2FGroup%203357.webp?alt=media&token=f6dbaab7-0523-45b8-8022-349cbaeef662"
                alt="Oceanography Batteries Assembly at Magpower"
              />
              {/* <p className="custom-product-card-p3">Oceanography Batteries</p> */}
            </Link>

            <Link
              to="/products/defence"
              className="custom-product-card"
              data-aos-delay="50"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-mirror="true"
              data-aos-anchor-placement="top-center"
            >
              <img
                loading="lazy"
                src={defence}
                alt="Batteries Assembly for Defence applications at Magpower"
              />
              {/* <p className="custom-product-card-p3">Oceanography Batteries</p> */}
            </Link>
          </div>
        </div>
      </Parallax>
      <Parallax>
        <div className="home-page-video-section" ref={myRef}>
          <h1 style={{ fontWeight: "bolder", fontSize: "48px" }}>
            MagPower Introduction Video
          </h1>
          <div className="home-page-video-card">
            <video
              width="100%"
              src={backGround}
              // muted
              controls
              // onMouseOver={(event) => event.target.play()}
              // onMouseOut={(event) => event.target.pause()}
              controlsList="nodownload"
              poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FGroup%203333.webp?alt=media&token=93326dc9-588e-43b4-9a62-c7544beca1e3"
              // autoPlay
              // loop
              style={{ width: "100%", objectFit: "contain" }}
            ></video>
          </div>
        </div>
      </Parallax>
      <Parallax>
        <div className="home-page-why-us">
          <div className="why-us-title">
            <h1>Why Us?</h1>
            <svg
              width="249"
              height="40"
              viewBox="0 0 249 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-2.18557e-07"
                y1="19.5"
                x2="53"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="63"
                y1="19.5"
                x2="249"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="55.5"
                y1="1.09278e-07"
                x2="55.5"
                y2="40"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="65.5"
                y1="9"
                x2="65.5"
                y2="31"
                stroke="#F0A202"
                stroke-width="5"
              />
            </svg>
          </div>
          <div className="why-us-cards-wrapper">
            <div className="why-us-card">
              <div className="why-us-card-img">
                <img
                  loading="lazy"
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Fwhy-us%2Fquality-assurance.webp?alt=media&token=0653664a-f10b-4270-ac66-060c2c36f60f"
                  alt="Battery Assembly Quality Assurance at magpower"
                />
              </div>
              <div className="why-us-content">
                <h4>Quality Assurance</h4>
                <p>
                  Our skilled talent and automated services are designed for
                  helping us produce high quality customised batteries packs and
                  meet market quality and safety standards and operating
                  temperature.
                </p>
                {/* <h5 className="why-us-read-more">Read More</h5> */}
                <div className="why-us-corner">{whyUsCardCorner}</div>
              </div>
            </div>
            <div className="why-us-card">
              <div className="why-us-card-img">
                <img
                  loading="lazy"
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Fwhy-us%2FCustom-Solutions.webp?alt=media&token=b88e1959-1005-4e46-8e62-38e9c0ff58b1"
                  alt="Custom Solutions Battery Assemblies at magpower Pune"
                />
              </div>
              <div className="why-us-content">
                <h4>Custom Solutions</h4>
                <p>
                  We support manufacturing of battery packs considering
                  available space, operating temperature, transportation
                  requirements, usage conditions, and charge/discharge
                  specifications.
                </p>
                {/* <h5 className="why-us-read-more">Read More</h5> */}
              </div>
              <div className="why-us-corner">{whyUsCardCorner}</div>
            </div>
            <div className="why-us-card">
              <div className="why-us-card-img">
                <img
                  loading="lazy"
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Fwhy-us%2Fzero-defect.webp?alt=media&token=2ac11ba2-2768-4b59-9380-baa4cdb77ea3"
                  alt="Zero Defects guarantee at MagPower"
                />
              </div>
              <div className="why-us-content">
                <h4>Zero Defects</h4>
                <p>
                  We take special care by testing battery cells at multiple
                  stages of assembly. Allowing us to pre-detect 100% of faulty
                  batteries and delivering defect free battery packs always.
                </p>
                {/* <h5 className="why-us-read-more">Read More</h5> */}
                <div className="why-us-corner">{whyUsCardCorner}</div>
              </div>
            </div>
            <div className="why-us-card">
              <div className="why-us-card-img">
                <img
                  loading="lazy"
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Fwhy-us%2Fadvanced-machinary.webp?alt=media&token=ea7ff6fe-96ea-4c43-8861-7c81897e423f"
                  alt="Advanced Machineries for Battery Assembly at magPower Pune"
                />
              </div>
              <div className="why-us-content">
                <h4>Advanced Machinery</h4>
                <p>
                  Our fleet of advanced, imported and specially modified
                  machines give us an edge, by allowing us produce
                  fast-turnaround, defect-free,quality battery packs with least
                  dependency on resources.
                </p>
                {/* <h5 className="why-us-read-more">Read More</h5> */}
                <div className="why-us-corner">{whyUsCardCorner}</div>
              </div>
            </div>
          </div>
        </div>
      </Parallax>

      <div className="home-page-machines-owned">
        <Parallax
          className="machines-owned-content"
          // translateX={[20, -10]}
        >
          <div>
            <div className="machines-owned-content-inner">
              <h2>Are you looking for Custom Battery Assembly Solutions? </h2>
              <p>
                With history of serving BEL and NIoT as a long term partnership,
                MagPower assures you to provide the best quality, defect-free
                and On-Time Battery Assembly services.
              </p>
              <button
                onClick={() => handleNavigate()}
                style={{ cursor: "pointer" }}
              >
                Lets Connect!{" "}
              </button>
            </div>
          </div>
        </Parallax>
        <Parallax
          // translateX={[-20, 10]}
          className="machines-owned-carousel"
        >
          <div>
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              interval={2000}
              showThumbs={false}
              showStatus={false}
            >
              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FAutomatic%20Ultrasonic%20Sealing%20machine.webp?alt=media&token=554ea3ee-ec0c-44a4-a711-ca4b24817839"
                alt="Advance Machinaries at MagPower Pune"
              />

              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FTwo%20Elgi%20Compressors.webp?alt=media&token=f6a83482-a562-4de8-8e10-62ba0b868706"
                alt="Quality Assurance at MagPower Pune"
              />
              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FBMS%20battery.webp?alt=media&token=372a3f04-6ccc-4212-bb0f-0d9c6a64d515"
                alt="BMS Battery Solutions at MagPower Pune"
              />
              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FGenerator-photo-_1_.webp?alt=media&token=a3ebca02-6033-4019-84a3-6c13c0b53770"
                alt="Power Generator at MagPower Pune"
              />
              <img
                loading="lazy"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2Fwhy-us%2Fzero-defect.webp?alt=media&token=2ac11ba2-2768-4b59-9380-baa4cdb77ea3"
                alt="Zero Defects Guarantee at MagPower Pune"
              />
            </Carousel>
          </div>
        </Parallax>
      </div>
      {/* <div className="home-page-testimonials">
        <div className="testimonals-section">
          <Testimonials />
        </div>
      </div> */}
      <div className="home-page-gallery">
        <div>
          <div className={model ? "model open" : "model"}>
            <img src={tempimage} loading="lazy" />
            <button
              className="image-gallery-button"
              onClick={() => setModel(false)}
            >
              <i
                className="fas fa-times cross-icon"
                style={{ cursor: "pointer" }}
              ></i>
            </button>
          </div>
        </div>
        <div className="gallery-title">
          <h1>MagPower Gallery</h1>
          <svg
            width="400"
            height="40"
            viewBox="0 0 249 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="-2.18557e-07"
              y1="19.5"
              x2="53"
              y2="19.5"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="63"
              y1="19.5"
              x2="400"
              y2="19.5"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="55.5"
              y1="1.09278e-07"
              x2="55.5"
              y2="40"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="65.5"
              y1="9"
              x2="65.5"
              y2="31"
              stroke="#F0A202"
              stroke-width="5"
            />
          </svg>
        </div>
        <div className="gallery-section-wrapper">
          <div className="image-gallery-wrapper">
            <div className="image-gallery-inner-wrapper">
              <img
                className="image-gallery-images"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_6087.webp?alt=media&token=a61653aa-7164-4739-be22-ee228ad20681"
                onClick={() =>
                  getImg(
                    `https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_6087.webp?alt=media&token=a61653aa-7164-4739-be22-ee228ad20681`
                  )
                }
                loading="lazy"
                alt="Team"
              />
              <p className="gallery-image-caption">Team</p>
            </div>
            <div className="image-gallery-inner-wrapper">
              <img
                className="image-gallery-images"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_5820.webp?alt=media&token=5f7ddb22-2c0b-40a1-953c-dc70f180f8ec"
                onClick={() =>
                  getImg(
                    `https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_5820.webp?alt=media&token=5f7ddb22-2c0b-40a1-953c-dc70f180f8ec`
                  )
                }
                loading="lazy"
                alt="Main Production Unit"
              />
              <p className="gallery-image-caption">Main Production Unit</p>
            </div>
            <div className="image-gallery-inner-wrapper">
              <img
                className="image-gallery-images"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_5704.webp?alt=media&token=52876693-3607-42fe-83c8-dcacc3c9bb5d"
                onClick={() =>
                  getImg(
                    `https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_5704.webp?alt=media&token=52876693-3607-42fe-83c8-dcacc3c9bb5d`
                  )
                }
                loading="lazy"
                alt="Pre-Assembly Facility"
              />
              <p className="gallery-image-caption">Pre-Assembly Facility</p>
            </div>
            <div className="image-gallery-inner-wrapper">
              <img
                className="image-gallery-images"
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_5605.webp?alt=media&token=e7b4d0dd-af42-42fe-b736-64fbb7d453de"
                onClick={() =>
                  getImg(
                    `https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fimage-gallery%2FIMG_5605.webp?alt=media&token=e7b4d0dd-af42-42fe-b736-64fbb7d453de`
                  )
                }
                loading="lazy"
                alt="Training Session at MagPower"
              />
              <p className="gallery-image-caption">
                Training Session at MagPower
              </p>
            </div>
          </div>
          <div className="gallery-see-all">
            <a href="/gallery">See All</a>
          </div>
        </div>
      </div>
      <section className="home-page-contact-us" id="contactus">
        <ContactUs />
      </section>
      <Footer />
    </div>
  );
}

export default Home;
