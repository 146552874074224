import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/generic/loader/Loader";
import Header from "../../components/header/Header";
import firebase_, { storageRef } from "../../services/firebaseConfig";
import { useParallaxController } from "react-scroll-parallax";

import "../../styles/gallery/gallery.css";
function GalleryPage() {
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [model, setModel] = useState(false);
  const [tempimage, setTempimage] = useState("");
  const parallaxController = useParallaxController();

  const getImg = (e, image) => {
    e.preventDefault();
    setTempimage(image);
    setModel(true);
  };
  async function fetchImages() {
    try {
      storageRef
        .listAll()
        .then((res) => {
          res.items.forEach((imageRef) => {
            displayImage(imageRef);
          });
        })
        .catch((err) => {
          console.log("Error while fetching the images", err);
        });
    } catch (err) {
      console.log("Error while fetching images", err);
    }
  }
  function displayImage(imageRef) {
    imageRef
      .getDownloadURL()
      .then(function (url) {
        setImageList((imageList) => [...imageList, url]);
        setLoading(false);
      })
      .catch(function (error) {
        console.log("Error while fetching the image links", error);
      });
  }
  useEffect(() => {
    setLoading(true);
    fetchImages();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "15%",
          alignItems: "flex-end",
        }}
      >
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <Header />
      <div className="image-gallery-container">
        <div>
          <div className={model ? "model open" : "model"}>
            <img
              src={tempimage}
              alt="Gallery Imag"
              loading="lazy"
              onLoad={() => parallaxController.update()}
            />
            <button
              className="image-gallery-button"
              onClick={() => setModel(false)}
            >
              <i
                class="fas fa-times cross-icon"
                style={{ cursor: "pointer" }}
              ></i>
            </button>
          </div>
        </div>
        <>
          <div className="image-gallery-title">
            <h1
              style={{
                fontSize: "xx-large",
                textAlign: "center",
                marginBottom: "1%",
              }}
            >
              MagPower Gallery
            </h1>
            <svg
              width="400"
              height="40"
              viewBox="0 0 249 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="-2.18557e-07"
                y1="19.5"
                x2="53"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="63"
                y1="19.5"
                x2="249"
                y2="19.5"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="55.5"
                y1="1.09278e-07"
                x2="55.5"
                y2="40"
                stroke="#F0A202"
                stroke-width="5"
              />
              <line
                x1="65.5"
                y1="9"
                x2="65.5"
                y2="31"
                stroke="#F0A202"
                stroke-width="5"
              />
            </svg>
          </div>
        </>
      </div>

      {/* ----------------------------------------------------------
      <div className="products-page-container">
        <div className="products-page-title">
          <h1
            style={{
              color: "white",
              fontSize: "xx-large",
              textAlign: "center",
            }}
          >
            MagPower Gallery
          </h1>
          <svg
            width="230"
            height="40"
            viewBox="0 0 249 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="-2.18557e-07"
              y1="19.5"
              x2="53"
              y2="19.5"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="63"
              y1="19.5"
              x2="249"
              y2="19.5"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="55.5"
              y1="1.09278e-07"
              x2="55.5"
              y2="40"
              stroke="#F0A202"
              stroke-width="5"
            />
            <line
              x1="65.5"
              y1="9"
              x2="65.5"
              y2="31"
              stroke="#F0A202"
              stroke-width="5"
            />
          </svg>
        </div>
      </div>
      ------------------------------------------------------- */}

      <div className="gallery-container">
        {imageList.map((item, index) => {
          return (
            <div className="gallery-img-card">
              <img
                alt="gallery imagee"
                src={item}
                loading="lazy"
                onClick={(e) => getImg(e, item)}
              />
            </div>
          );
        })}
      </div>
      <Footer />
      {/* {JSON.parse(imageList)} */}
    </div>
  );
}

export default GalleryPage;
