import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import "../../styles/footer.css";
import { NavLink } from "react-router-dom";
const today = new Date();
function Footer() {
  return (
    <>
      <div>
        <div className="footer-container">
          <section className="footer-col1">
            <p className="footer-headings">
              <LocationOnOutlinedIcon sx={{ fontSize: 35 }} />
              Address
            </p>
            <p className="footer-text">
              Office : RENUKA SMRUTI 19,Hingane Home Colony
              Karvenagar,Pune-411052
            </p>
            <p className="footer-text" style={{ marginTop: "5%" }}>
              Works : Shed No.2, S. No. 77/4, Vishnu-malati Industrial Esate,
              Shivne, Pune- 411 023
            </p>
          </section>
          <section className="footer-col2">
            <div>
              <p className="footer-headings">
                <LocalPhoneOutlinedIcon sx={{ fontSize: 30 }} />
                Contact
              </p>
              <p className="footer-text">
                Sandeep Pandit :+919850551552
              </p>
              <p className="footer-text">
                Ashpak Mokashi :+918530873426
              </p>
            </div>
            <div style={{ marginTop: "5%" }}>
              <p className="footer-headings">
                <MarkunreadOutlinedIcon sx={{ fontSize: 30 }} />
                Email
              </p>
              <p className="footer-text">info@magpower.in</p>
              <p className="footer-text">magpowerpune@gmail.com</p>
            </div>
            <div style={{ marginTop: "5%", marginBottom: "2%" }}>
              <p className="footer-headings">
                <LinkOutlinedIcon sx={{ fontSize: 30 }} />
                Social Links
              </p>
              <div className="footer-col2-icon-wrapper">
              <a href="https://www.instagram.com/mag.power.pune/">
                <InstagramIcon sx={{ fontSize: 30 }} />
              </a>
              <a href="https://www.facebook.com/MagPower.Pune">
                <FacebookIcon sx={{ fontSize: 30 }} />
              </a>
              <a href="https://youtu.be/J39WB7mkUmI">
                <YouTubeIcon sx={{ fontSize: 30 }} />
              </a>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Ffooter%2Flinkedin.webp?alt=media&token=6bb4696b-1b74-4997-a55a-67f9dafb8f07"
                  alt="LinkedIn Icon"
                  loading="lazy"
                />
                <TwitterIcon sx={{ fontSize: 30 }} />
              </div>
            </div>
            <div></div>
          </section>
          <section className="footer-col3">
            <h1 className="footer-headings-quick-link">Quick Links</h1>
            <NavLink
              to="/"
              style={{ textDecoration: "none", color: "#fff", lineHeight: "2" }}
            >
              <p>Home</p>
            </NavLink>
            <NavLink
              to="/aboutus"
              style={{ textDecoration: "none", color: "#fff", lineHeight: "2" }}
            >
              <p>About Us</p>
            </NavLink>
            <NavLink
              to="/gallery"
              style={{ textDecoration: "none", color: "#fff", lineHeight: "2" }}
            >
              <p>Gallery</p>
            </NavLink>
            <NavLink
              to="/careers"
              style={{ textDecoration: "none", color: "#fff", lineHeight: "2" }}
            >
              <p>Careers</p>
            </NavLink>
            <NavLink
              to="/products"
              style={{ textDecoration: "none", color: "#fff", lineHeight: "2" }}
            >
              <p>Product</p>
            </NavLink>
            <NavLink
              to="/contact"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <p>Contact</p>
            </NavLink>
          </section>
        </div>

        <div
          className="company-names-container"
          style={{ paddingBottom: "2%" }}
        >
          <div className="footer-company-section">
            <div className="Footer-bottom">
              <span className="">
                <p className="copyright-link">
                  &copy; {today.getFullYear()}&nbsp;
                  <a
                    href="https://magpowerpune.com/"
                    className="copyright-link"
                    target="_abc"
                  >
                    MagPower
                  </a>
                  &nbsp;|&nbsp;All Rights Reserved | Designed and Developed By
                  &nbsp;
                  <a
                    href="https://www.scrobits.com/"
                    className="scrobits-link"
                    target="_abc"
                  >
                    Scrobits Technologies
                  </a>
                </p>
              </span>
            </div>
          </div>
          {/* <div className="flex-bottom-container Footer-bottom-text">
          Designed and Developed By &nbsp;
          <span>
            <a
              href="https://www.scrobits.com/"
              className="scrobits-link"
              target="_abc"
            >
              Scrobits Technologies
            </a>
          </span>
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Footer;
