import React, { useState, useRef } from "react";
import ContactUs from "../../components/contactus/ContactUs";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

function Contact() {
  const myRef = useRef(null);

  const [userName, setUserName] = useState("");
  const [userAge, setUserAge] = useState("");
  const handleChange = (e) => {};
  return (
    <div>
      <Header myRef={myRef} />
      <div className="home-page-contact-us" id="contactus" ref={myRef}>
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
