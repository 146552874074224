import { Modal, Typography } from "@mui/material";
import { Box, width } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/home/hero.css";
import "../../styles/home.css";
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
function Hero(props) {
  const executeScroll = () => scrollToRef(props.myRef);
  const [openModal, setOpenModal] = useState(false);
  // const backGround = HeroBackgroundVideo;
  const backGround =
    "https://securitynewscast-space.sgp1.cdn.digitaloceanspaces.com/magpower/magpower%20short%20vid.mp4";
  let navigate = useNavigate();
  const handleNavigate = () => {
    navigate("#contactus");
  };
  const handleModalOpen = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };
  const handleModalClose = (e) => {
    e.preventDefault();
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 1000 }} sm={{ ...style, width: 300 }}>
          <div>
            <video
              width="100%"
              title="MagPower Pune, Battery Assemblers Introduction video"
              src={backGround}
              // muted
              autoPlay
              controls
              // onMouseOver={(event) => event.target.play()}
              // onMouseOut={(event) => event.target.pause()}
              controlsList="nodownload"
              poster="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhome%2FGroup%203333.webp?alt=media&token=93326dc9-588e-43b4-9a62-c7544beca1e3"
              // autoPlay
              // loop
              style={{ width: "100%", objectFit: "contain" }}
            ></video>
          </div>
        </Box>
      </Modal>
      <div className="home-container">
        <div className="home-page-hero-section">
          <video
            src={backGround}
            muted
            // controls
            autoPlay
            loop
            className="hero-background-video"
          ></video>
          <div className="hero-section-wrapper">
            <div className="hero-title-section">
              <h1
                style={{
                  fontSize: "56px",
                  lineHeight: "67.2px",
                  fontWeight: "600",
                }}
              >
                Best Battery Assembly <br />
                Facility in India
              </h1>
            </div>
            <div className="hero-description-contact">
              <p>
                MagPower is one of the most experienced and Quality battery
                assembly / battery pack manufacturers in India. Providing
                customized solutions in medical, defence, aerospace, industrial
                and green emergy markets.
              </p>
            </div>
            <div className="hero-description-contact-btn">
              <a style={{ cursor: "pointer" }} href="/#contactus">
                Reach Out To Us
              </a>
            </div>
            <div className="hero-feats">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhero%2FCelebrating%2030%2B%20years-01%201Hero%20Section%20Trust%20Builders.webp?alt=media&token=057a7a39-46c3-471d-8d4e-581f94da7a8f"
                alt="Celebrating 30 years"
                loading="lazy"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhero%2FBharat%20Electronics%20India.webp?alt=media&token=c8993543-327d-437d-a485-8c181399571c"
                alt="Bharat Electronics logo"
                loading="lazy"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhero%2FISO%20certified%20company.webp?alt=media&token=3ab65b54-bc34-4a7f-834c-3a58ab2f58eb"
                alt="ISO logo"
                loading="lazy"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhero%2FNIOT%20logo.webp?alt=media&token=7ee1f1a2-7b03-44be-b553-a8a78eaaaf5c"
                alt="NIOT logo"
                loading="lazy"
              />
            </div>
            <div className="hero-end-row">
              <a
                className="hero-end-circle-arrow"
                href="#home-page-trusted-by-section"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhero%2FDownArrow.webp?alt=media&token=d99bf92d-5ae0-4277-a9cf-03b6b1f30ee7"
                  alt="Arrow"
                  loading="lazy"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/magpower-site.appspot.com/o/images%2Fhero%2FCircle.webp?alt=media&token=ac822884-e935-47f7-9e4e-18a594ae9fab"
                  alt="Circle"
                  loading="lazy"
                />
              </a>
              <div className="hero-watch-video" onClick={handleModalOpen}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  style={{ fill: "inherit" }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 2C8.26875 2 2 8.26875 2 16C2 23.7313 8.26875 30 16 30C23.7313 30 30 23.7313 30 16C30 8.26875 23.7313 2 16 2ZM16 27.625C9.58125 27.625 4.375 22.4188 4.375 16C4.375 9.58125 9.58125 4.375 16 4.375C22.4188 4.375 27.625 9.58125 27.625 16C27.625 22.4188 22.4188 27.625 16 27.625Z"
                    // fill="#FDFFFC"
                  />
                  <path
                    d="M21.1281 15.7907L14.3031 10.8282C14.2658 10.8011 14.2216 10.7848 14.1756 10.7813C14.1296 10.7777 14.0834 10.7869 14.0423 10.808C14.0013 10.829 13.9668 10.861 13.9428 10.9004C13.9187 10.9399 13.9061 10.9852 13.9063 11.0313V20.9563C13.9063 21.0024 13.9191 21.0476 13.9432 21.0869C13.9672 21.1262 14.0017 21.158 14.0427 21.179C14.0837 21.2 14.1297 21.2093 14.1757 21.2059C14.2216 21.2024 14.2657 21.1863 14.3031 21.1595L21.1281 16.1938C21.1604 16.171 21.1867 16.1408 21.2048 16.1058C21.223 16.0707 21.2325 16.0318 21.2325 15.9923C21.2325 15.9528 21.223 15.9139 21.2048 15.8788C21.1867 15.8437 21.1604 15.8135 21.1281 15.7907Z"
                    // fill="#FDFFFC"
                  />
                </svg>

                <p>Watch Full Video</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
